import { Injectable } from '@angular/core';
import { PermissionService } from '../auth/permission.service';
import { countMapString } from 'src/app/shared/helpers/count-map.helper';
import { AmplitudeEventTypeListEnum } from 'src/app/shared/enums/amplitude.enum';
import { AmplitudeStartEAppFunnelModel, PaperAppUploadAmplitudeModel } from 'src/app/shared/models/amplitude.models';
import { SessionStoreService } from '../stores/session-store.service';
import { AmplitudeSurveyModel } from 'src/app/shared/models/amplitude-survey.models';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeEventV2Service {
  private instance = amplitude.createInstance();
  private instanceName = 'v2';
  private eventTypeList = AmplitudeEventTypeListEnum;
  private routeParamFilterList = [
    'PolicyDetailsV2',
    'PolicyDetails',
  ];
  constructor(
    private permissionService: PermissionService,
    private sessionStore: SessionStoreService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  /**
   * Initialize Amplitude SDK & log default events
   */
  initAmplitude(): void {
    const userId = this.sessionStore?.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.Id : this.sessionStore.User.Id;
    this.instance.init(environment.amplitudeV2ApiKey, userId, {
      instanceName: this.instanceName,
      autocapture: {
        elementInteractions: false,
        attribution: false,
        fileDownloads: false,
        formInteractions: false,
        pageViews: false,
        sessions: true,
      }
    }).promise.then(() => {
      this.initUserProperties();
      this.initSessionReplayTracking();
      this.logBrowserSize();
      this.logNavigationEvent();
    });
  }

  initUserProperties(): void {
    const properties: AmplitudeV2UserProperties = {
      elevate_access: this.permissionService.hasOnePermission('Elevate:Access'),
      dark_mode: this.sessionStore.OnBehalfOfUser ? null : this.checkDarkMode(), // on behalf of
      redtail_user: !!this.sessionStore.User.IsRedtailUser,
      salesforce_user: !!this.sessionStore.IsSalesforceUser,
      wealthbox_user: !!this.sessionStore.IsWealthboxUser,
      tot_user: !!this.sessionStore.User.IsToTUser,
      sales_team: this.sessionStore.SalesTeam,
      name: this.sessionStore.OnBehalfOfUser ? `${this.sessionStore.OnBehalfOfUser?.FirstName} ${this.sessionStore.OnBehalfOfUser?.LastName}` : `${this.sessionStore.User?.FirstName} ${this.sessionStore.User?.LastName}`, // on behalf of
      email: this.sessionStore.OnBehalfOfUser ? this.sessionStore.OnBehalfOfUser.Email : this.sessionStore.User.Email, // on behalf of
      arc_status: this.sessionStore.User.AgentArcStatus?.Status, // on behalf of
    };

    this.updateUserProperties(properties);
  }

  /**
   * @param userProperties user properties will automatically be converted to `snake_case` with the prefix `user_` in Amplitude. Please be specific in naming these. Ex: `user_lifecycle_has_scheduled_export`
   */
  updateUserProperties(userProperties: AmplitudeV2UserProperties | object): void {
    for (const [key, value] of Object.entries(userProperties)) {
      const property = new amplitude.Identify().set(`user_${this.convertCamelToSnake(key)}`, value);
      this.instance.identify(property);
    }
  }

  /**
   * Capture event replay
   * @param sampleRate Set the percentage of all active users to capture their replays. Must be a decimal between 0-1 with 1 = 100% & 0 = 0%
   */
  initSessionReplayTracking(sampleRate?: number): void {
    // Limit these to production since we only have 10k
    if (environment.production) {
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: sampleRate || 1,
      });
      this.instance.add(sessionReplayTracking);
    }
  }

  private logBrowserSize(): void {
    this.breakpointObserver.observe([
      Breakpoints.XSmall, // "(max-width: 599.98px)"
      Breakpoints.Small, // "(min-width: 600px) and (max-width: 959.98px)"
      Breakpoints.Medium, // "(min-width: 960px) and (max-width: 1279.98px)"
      '(min-width: 1280px) and (max-width: 1439.98px)',
      '(min-width: 1440px) and (max-width: 1919.98px)',
      Breakpoints.XLarge, // "(min-width: 1920px)"
    ]).subscribe({
      next: res => {
        const breakpoints = res.breakpoints;
        for (const [key, value] of Object.entries(breakpoints)) {
          if (value) {
            const property = {
              deviceBrowserSize: key,
            };
            this.updateUserProperties(property);
          }
        }
      }
    });
  }

  logNavigationEvent(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const prevPath: string | undefined = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
        const currentPath: string | undefined = this.router.getCurrentNavigation()?.finalUrl?.toString();
        const eventProperties: AmplitudeV2EventProperties = {
          prev_page: this.trimDynamicPath(prevPath),
          current_page: this.trimDynamicPath(currentPath),
        };
        this.logEvent(`Navigation - ${eventProperties.current_page}`, undefined, eventProperties);
      }
    });
  }

  /**
   * Trim query params, fragments & dynamic pages that contains IDs (ex: Policy Details) to make data cleaner in Amplitude
   * @param path router path `path/to/page?query=value` `path/to/page#12345` `path/to/page/:id`
   * @returns trimmed router path `path/to/page`
   */
  private trimDynamicPath(path: string | undefined): string | undefined {
    let res = path;
    if (res) {
      const matchPath = this.routeParamFilterList.find(p => path.includes(p));
      if (matchPath) {
        res = res.split(matchPath)[0] + matchPath;
      }
      if (res.includes('#')) {
        res = res.split('#')[0];
      }

      if (res.includes('?')) {
        res = res.split('?')[0];
      }
    }

    return res;
  }

  /**
   * Set all AAmplitude properties to have snake_case
   * @param str event/user property input. Can be any casing & will be converted to snake_case
   * @returns snake_case
   */
  private convertCamelToSnake(str: string): string {
    return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase();
  }

  /**
   * Convert eventProperties to snake_case & attach an optional prefix for easier query in amplitude
   * @param eventProperties any `Record<string, string | string[] | number | number[] | boolean>`
   * @param prefix (Optional) prefix string to be appended to each object's property for easier query
   * @returns Ex: "prefix_[property_name]"
   */
  private mapEventProperties(eventProperties: object, prefix?: string): AmplitudeV2EventProperties {
    let properties: AmplitudeV2EventProperties;
    for (const [key, value] of Object.entries(eventProperties)) {
      properties = Object.assign({
        [prefix + '_' + this.convertCamelToSnake(key)]: value as string | string[] | number | number[] | boolean,
      }, properties);
    }
    return properties;
  }

  /**
   * @param eventType name of event in Amplitude
   * @param clickTarget event property `event_click_target` specifying the element that was clicked on
   * @param eventProperties additional event properties for querying. Please be specific in naming these. Event properties will automatically be converted to `snake_case` with the prefix `event_` in Amplitude. Ex: `event_policy_details_carrier`
   * @param userProperties user properties will automatically be converted to `snake_case` with the prefix `user_` in Amplitude. Please be specific in naming these. Ex: `user_lifecycle_has_scheduled_export`
   */
  private logEvent(eventType: string, clickTarget?: string, eventProperties?: Record<string, string | string[] | number | number[] | boolean>, userProperties?: Record<string, string | string[] | number | number[] | boolean>): void {

    eventProperties = {
      ...eventProperties, ...{
        click_target: clickTarget || undefined,
        current_page: this.trimDynamicPath(location.pathname),
        logged_in_user_name: `${this.sessionStore.User?.FirstName} ${this.sessionStore.User?.LastName}`,
        logged_in_user_email: this.sessionStore.User?.Email,
        logged_in_user_type: (this.sessionStore.User.IsArcUser || this.sessionStore.User?.Email?.includes('@figmarketing.com')) ? 'fig' : this.sessionStore.OnBehalfOfUser ? 'back_office' : 'self',
      }
    };

    eventProperties = this.mapEventProperties(eventProperties, 'event');

    if (userProperties) {
      userProperties = this.mapEventProperties(userProperties, 'user');
    }

    const event: import('@amplitude/analytics-types').BaseEvent = {
      event_type: `[Portal] ${eventType}`,
      event_properties: eventProperties,
      user_properties: userProperties || undefined,
    };

    this.instance.track(event);
  }

  /** The beginning of my masterplan to convince others for a dark mode in Portal */
  private checkDarkMode(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Logs a simple "Click" `event_type`
   * @param clickTarget specify the interaction element `click_target` that user can click on for querying
   * @param eventProperties additional event properties for querying. Please be specific in naming these. Event properties will automatically be converted to `snake_case` with the prefix `event_` in Amplitude. Ex: `event_policy_details_carrier`
   */
  logClickEvent(clickTarget: string, eventProperties?: object): void {
    this.logEvent(`${this.eventTypeList.click} - ${clickTarget}`, clickTarget, eventProperties as Record<string, string | string[] | number | number[] | boolean>);
  }

  /**
   * Logs a custom event
   * @param eventType name of the event showing up in Amplitude
   * @param clickTarget event property `event_click_target` specifying the element that was clicked on
   * @param eventProperties additional event properties for querying. Please be specific in naming these. Event properties will automatically be converted to `snake_case` with the prefix `event_` in Amplitude. Ex: `event_policy_details_carrier`
   * @param userProperties user properties will automatically be converted to `snake_case` with the prefix `user_` in Amplitude. Please be specific in naming these. Ex: `user_lifecycle_has_scheduled_export`
   */
  logCustomEvent(eventType: string, clickTarget?: string, eventProperties?: object | false, userProperties?: object | false): void {
    this.logEvent(eventType, clickTarget, eventProperties as Record<string, string | string[] | number | number[] | boolean>, userProperties as Record<string, string | string[] | number | number[] | boolean>);
  }

  /**
   * Logs a custom `event_type` called "Business Reporting by Report Type"
   * @param reportType the product type of the policy list. Ex: Fixed, Variable, etc.
   * @param policyListType The status if the plicy list. Ex: Pending, Inforce, Lifecycle, etc.
   * @param policyCount the number of policy, which will then be group into several pre-defined thresholds. I.e. '0-100', '101-200', etc.
   */
  logBusinessReporting(reportType: string, policyListType: string, policyCount: number,): void {
    this.logCustomEvent(this.eventTypeList.businessReportingByReportType, null, {
      business_report_type: reportType,
      business_report_policy_list_type: policyListType,
      business_report_policy_count: countMapString(policyCount)
    });
  }

  /**
   * Logs a custom `event_type` called "Paper App Upload - `model.step`"
   * @param model model specifying the `step` in the upload, `click_target`, `file_count`
   */
  logPaperAppUpload(model: PaperAppUploadAmplitudeModel): void {
    //Append this here to not mess up historical data on production
    const mappedModel = this.mapEventProperties(model, 'paper_app');
    this.logCustomEvent(`${this.eventTypeList.paperAppUpload} - ${mappedModel['paper_app_click_target']}`, String(mappedModel['paper_app_click_target']), mappedModel);
  }

  /**
   * Used for funnel chart tracking percentage of fallout users when going through the Start E-App workflow.
   * This thing is used in various places within the E-App process so please Ctrl+F & make sure nothing breaks because nobody will notice until it's too late!
   * @param stepName Each step in the funnel workflow
   * @param formValue Extrapolated `start-application.form.component` form value
   */
  logEAppFunnel(stepName: 'Start Workflow' | 'Complete Form' | 'Submit Application' | 'End Workflow Success' | 'Abandon Workflow', formValue: AmplitudeStartEAppFunnelModel): void {
    //Append this here to not mess up historical data on production
    const mappedModel = this.mapEventProperties(formValue, 'start_eapp');
    this.logCustomEvent(`E-App Funnel - ${stepName}`, undefined, mappedModel);
  }

  logSurveyAnswer(surveyName: string, surveyModel: AmplitudeSurveyModel): void {
    this.logCustomEvent(`Survey - ${surveyName}`, undefined, surveyModel);
  }
}
/**
 * All of these should track on behalf of user if applicable
 */
interface AmplitudeV2UserProperties extends Record<string, string | string[] | number | number[] | boolean> {
  elevate_access: boolean;
  dark_mode: boolean;
  redtail_user: boolean;
  salesforce_user: boolean;
  wealthbox_user: boolean;
  tot_user: boolean;
  sales_team: string;
  name: string;
  email: string;
}

type LoggedInUserType = 'self' | 'fig' | 'back_office';

interface AmplitudeV2EventProperties extends Record<string, string | string[] | number | number[] | boolean> {
  /** The UI element that was interacted with */
  event_click_target?: string,
  /** The current page that event event takes place */
  event_current_page?: string,
  /** Ful name of the logged in user */
  event_logged_in_user_name?: string,
  /** Email of the logged in user */
  event_logged_in_user_email?: string,
  /** The type of logged in user. If the logged in user is an ARC user, it will be `arc`. If no impersonation is taking place, it will be `self`. Otherwise it will be `back_office` */
  event_logged_in_user_type?: LoggedInUserType,
}