<section class="flex items-center flex-none p-20 sm:p-30 border-b border-gray-lighter">
  <div class="flex justify-between items-center w-full">
      <h1 class="text-gray-600 text-xl font-bold">
          Redtail CRM Integration
      </h1>
      <button mat-button
              matTooltip="Close Dialog"
              type="button"
              mat-dialog-close
              class="ml-auto icon-button is-primary is-inverted"
              (click)="closeModal()">
          <i class="material-icons">close</i>
      </button>
  </div>
</section>
<div class="flex flex-col w-full h-full mx-auto justify-center dialog-content p-20 sm:p-30">
  <section class="flex flex-col justify-center h-full gap-y-20" [@fadeIn]="licenses">
    <span class="text-base">
      Please confirm your NPN, CRD and Redtail ID numbers below so we can properly match your Lifecycle policy data to your Redtail CRM contacts. If the information below is not correct, please contact our support staff.
    </span>
    <span class="font-bold">Verify your personal identification numbers below:</span>
    <div class="flex flex-col gap-y-10 w-320">
      <div class="flex justify-between">
        <span class="text-base">National Producer Number (NPN): </span>
        <span class="text-base font-bold">{{licenses.Npn ? licenses.Npn : '--'}}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-base">Central Registration Depository (CRD): </span>
        <span class="text-base font-bold">{{licenses.Crd ? licenses.Crd : '--'}}</span>
      </div>
    </div>
  </section>
</div>
<section class="flex flex-none p-20 ml-auto w-full border-t border-solid lg:p-30 border-gray-lighter">
  @if (!isLoading){
    <button class="ml-auto button is-primary is-inverted is-outlined" type="button" tabindex="-1" 
      appCyData="contactSupport" (click)="openSupportModal()">
      Contact Support
    </button>
    @if (!isEnrolled){
      <button class="ml-10 button is-primary" type="button" tabindex="-1" [disabled]="isSubmitting"
        appCyData="submitRedtailRequest" (click)="submitRequest()">
        Submit{{isSubmitting ? 'ting' : ''}} Request
      </button>
    } @else {
      <button class="ml-10 button is-primary is-success" type="button" tabindex="-1"
        appCyData="closeRequest" (click)="closeModal()">
        Enrolled
      </button>
    }
  }
</section>