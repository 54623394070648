import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedtailEnrolleeApiService {
  private baseUrl = '/api/RedtailEnrollee';

  constructor(private http: HttpClient){}

  canEnroll(): Observable<boolean>{
    return this.http.get<boolean>(`${this.baseUrl}/CanEnroll`,{});
  }

  deleteEnrollee(): Observable<boolean>{
    return this.http.delete<boolean>(`${this.baseUrl}/DeleteEnrollee`,{});
  }

  isEnrolled(): Observable<boolean>{
    return this.http.get<boolean>(`${this.baseUrl}/IsEnrolled`,{});
  }

  registerUser(): Observable<string>{
    return this.http.post<string>(`${this.baseUrl}/Register`,{});
  }
}