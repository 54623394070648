import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailApiService } from 'src/app/core/services/http/email-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { createSupportEmailTemplate, EmailRequestTypes } from 'src/app/shared/constants/support.constants';
import { User } from 'src/app/shared/models/user.models';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SupportTopic } from 'src/app/shared/models/email.models';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})

export class SupportComponent {
  loading = false;
  message = '';
  toastClass = '';

  supportTopics: SupportTopic[] = EmailRequestTypes;
  selectedSupportTopic: SupportTopic | null = null;

  supportForm = new UntypedFormGroup({
    FullName: new UntypedFormControl(this.sessionStore.User.FirstName + ' ' + this.sessionStore.User.LastName, [Validators.required]),
    Email: new UntypedFormControl(this.sessionStore.User.Email, [Validators.required, Validators.email]),
    Phone: new UntypedFormControl(''),
    SupportTopic: new UntypedFormControl(this.selectedSupportTopic, Validators.required),
    Message: new UntypedFormControl('', Validators.required),
  });

  //reactive forms bypasses native validation by default in favor of form controller Validators, this touches all the form fields in event user tries to submit an invalid form without touching all required fields
  private markFormAsTouched(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(field => { // {1}
      const control = form.get(field);            // {2}
      control?.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  constructor(
    private dialog: MatDialog,
    private emailApiService: EmailApiService,
    private sessionStore: SessionStoreService,
    private _snackBar: MatSnackBar,
  ) { }

  onSubmit(form: UntypedFormGroup): void {
    this.loading = true;
    this.markFormAsTouched(form);

    if (this.supportForm.invalid) {
      this.markFormAsTouched(form);
      this.loading = false;
    } else {
      const user: User = {
        ...this.sessionStore.User,
        FullName: form.value.FullName,
        Email: form.value.Email,
        Phone: form.value.Phone,
      };

      const message: string = form.value.Message;
      const supportTopic = form.value.SupportTopic;
      const email = createSupportEmailTemplate(user, message, supportTopic);

      this.emailApiService.createAndSendSupportEmail(email)
        .subscribe({
          next: () => {
            this.message = 'Ticket submitted successfully!';
            this.toastClass = 'is-success';
            this.dialog.closeAll();
          },
          error: () => {
            this.message = 'There was a problem sending your ticket. Please try again shortly.';
            this.toastClass = 'is-error';
          }
        })
        .add(() => {
          this.loading = false;
          this._snackBar.open(this.message, 'Close', {
            panelClass: [this.toastClass]
          });
        });
    }
  }


}

