import { Component, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { SalesforceSsoService } from 'src/app/core/services/http/salesforce-sso.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsModalComponent } from '../../manage-integrations-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';

@Component({
  animations: [fadeIn],
  selector: 'app-salesforce-integration',
  templateUrl: './salesforce-integration.component.html'
})
export class SalesforceIntegrationComponent implements OnDestroy {
  isSalesforceConnected = this.sessionStore.IsSalesforceUser;
  isSubmitting = false;
  closeTimer$ = new Subject<boolean>();

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent, ManageIntegrationResModel>,
    private salesforceSsoService: SalesforceSsoService,
    private sessionStore: SessionStoreService,
    private snackbarService: SnackbarService
  ){}

  ngOnDestroy(): void {
    this.closeTimer$.next();
  }

  activateSalesforce(): void {
    this.isSubmitting = true;
    window.open('/api/Salesforce/Sso/', '_blank', 'width=800,height=650');
    this.startPolling();
  }

  deactivateSalesforce(): void {
    this.isSubmitting = true;
    this.salesforceSsoService.removeAuthentication().subscribe(() => {
      this.isSalesforceConnected = false;
      this.sessionStore.setUserSalesforceStatus(this.isSalesforceConnected);
      this.isSubmitting = false;
      this.snackbarService.openSnackbar(ManageIntegrationsEnum.Salesforce + ' deactivated successfully!', ToastClassEnum.success);
      this.amplitudeEventService.logCustomEvent(ManageIntegrationsEnum.Salesforce, 'CRM Connection', {
        crmIsConnected: false,
      }, false);
      this.amplitudeEventService.updateUserProperties({
        salesforceUser: false,
      });
      this.dialogRef.close({
        isConnected: false,
        crmIntegration: ManageIntegrationsEnum.Salesforce,
      });
    });
  }

  openConfirmationModal(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: 'Are you sure you want to disconnect your Salesforce account from Agent Portal?',
        isWarning: true,
        confirmationButtonText: 'Disconnect Salesforce'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.deactivateSalesforce();
      } else return;
    });
  }

  startPolling(requestInterval = 1000): void {
    timer(0, requestInterval)
      .pipe(
        switchMap(() => this.salesforceSsoService.isConnected()),
        takeUntil(this.closeTimer$)
      )
      .subscribe((res) => {
        this.isSalesforceConnected = res;
        if (this.isSalesforceConnected) {
          this.sessionStore.setUserSalesforceStatus(this.isSalesforceConnected);
          this.isSubmitting = false;
          this.closeTimer$.next();
          this.amplitudeEventService.logCustomEvent(ManageIntegrationsEnum.Salesforce, 'CRM Connection', {
            crmIsConnected: res,
          }, false);
          this.amplitudeEventService.updateUserProperties({
            salesforceUser: res,
          });
          this.dialogRef.close({
            isConnected: true,
            crmIntegration: ManageIntegrationsEnum.Salesforce,
          });
        }
      });
  }
}
