import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { WealthboxApiService } from 'src/app/core/services/http/wealthbox-api.service';
import { SessionStoreService } from 'src/app/core/services/stores/session-store.service';
import { ManageIntegrationsEnum } from 'src/app/shared/enums/integrations.enum';
import { ManageIntegrationResModel } from 'src/app/shared/models/intergrations.models';
import { ManageIntegrationsModalComponent } from '../../manage-integrations-modal.component';
import { fadeIn } from 'src/app/animations';
import { AmplitudeEventService } from 'src/app/core/services/amplitude/amplitude-event.service';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';
import { ConfirmationComponent } from '../../../confirmation/confirmation.component';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';

@Component({
  animations: [fadeIn],
  selector: 'app-wealthbox-integration',
  templateUrl: './wealthbox-integration.template.html',
})
export class WealthboxIntegrationComponent implements OnDestroy {
  isWealthboxConnected = this.sessionStore.IsWealthboxUser;
  isSubmitting = false;
  closeTimer$ = new Subject<boolean>();

  constructor(
    private amplitudeEventService: AmplitudeEventService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ManageIntegrationsModalComponent, ManageIntegrationResModel>,
    private sessionStore: SessionStoreService,
    private snackbarService: SnackbarService,
    private wealthboxApiService: WealthboxApiService,
  ) { }

  ngOnDestroy(): void {
    this.closeTimer$.next();
  }

  activateWealthbox(): void {
    this.isSubmitting = true;
    window.open('/api/Wealthbox/Sso/', '_blank', 'width=800,height=650');
    this.startPolling();
  }

  deactivateWealthbox(): void {
    this.isSubmitting = true;
    this.wealthboxApiService.removeAuthentication().subscribe(() => {
      this.isWealthboxConnected = false;
      this.sessionStore.setUserWealthboxStatus(this.isWealthboxConnected);
      this.isSubmitting = false;
      this.snackbarService.openSnackbar(ManageIntegrationsEnum.Wealthbox + ' deactivated successfully!', ToastClassEnum.success);
      this.amplitudeEventService.logCustomEvent(ManageIntegrationsEnum.Wealthbox, 'CRM Connection', {
        crmIsConnected: false,
      }, false);
      this.amplitudeEventService.updateUserProperties({
        wealthboxUser: false,
      });
      this.dialogRef.close({
        isConnected: false,
        crmIntegration: ManageIntegrationsEnum.Wealthbox,
      });
    });
  }

  openConfirmationModal(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: 'Are you sure you want to disconnect your Wealthbox account from Agent Portal?',
        isWarning: true,
        confirmationButtonText: 'Disconnect Wealthbox'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.deactivateWealthbox();
      } else return;
    });
  }

  startPolling(requestInterval = 1000): void {
    timer(0, requestInterval)
      .pipe(
        switchMap(() => this.wealthboxApiService.isConnected()),
        takeUntil(this.closeTimer$)
      )
      .subscribe((res) => {
        this.isWealthboxConnected = res;
        if (this.isWealthboxConnected) {
          this.sessionStore.setUserWealthboxStatus(this.isWealthboxConnected);
          this.isSubmitting = false;
          this.closeTimer$.next();
          this.amplitudeEventService.logCustomEvent(ManageIntegrationsEnum.Wealthbox, 'CRM Connection', {
            crmIsConnected: res,
          }, false);
          this.amplitudeEventService.updateUserProperties({
            wealthboxUser: res,
          });
          this.dialogRef.close({
            isConnected: true,
            crmIntegration: ManageIntegrationsEnum.Wealthbox,
          });
        }
      });
  }
}
