import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PolicyStatement } from '../../../shared/models/permission.models';
import { EntryResponse, ListResponse, SingleResponse } from '../../../shared/models/generic.models';
import { FigAuthUserId, User, UserDelegate } from '../../../shared/models/user.models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AgentArcStatus } from 'src/app/shared/models/agent.models';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private http: HttpClient) { }

  readonly user = this.http.get<SingleResponse<User>>('/api/Users/LoggedInUser').pipe(map((x: SingleResponse<User>) => x.Entries), shareReplay(1));

  deleteUserLogo(userId: string): Observable<any> {
    return this.http.delete(`/api/Users/${userId}/Logo`);
  }

  get(userId: string): Observable<User> {
    return this.http.get<SingleResponse<User>>(`/api/Users/${userId}`).pipe(map((x: SingleResponse<User>) => x.Entries));
  }

  getIsArcUser(): Observable<boolean> {
    return this.http.get<boolean>('/api/Users/Fig/Arc/IsArcUser');
  }

  getLoggedInUserPermissions(): Observable<PolicyStatement[]> {
    return this.http.get<ListResponse<PolicyStatement>>('/api/Users/Me/Permissions/All')
      .pipe(map((x: ListResponse<PolicyStatement>) => x.Entries));
  }

  getUserFigAuthId(userId: string): Observable<FigAuthUserId> {
    return this.http.get<EntryResponse<FigAuthUserId>>(`/api/Users/${userId}/FigAuth`)
      .pipe(map((x: EntryResponse<FigAuthUserId>) => x.Entry));
  }

  getUserInfo(): Observable<User> {
    return this.http.get<SingleResponse<User>>('/api/Users/LoggedInUser')
      .pipe(map((x: SingleResponse<User>) => x.Entries));
  }

  getUserLogo(userId: string): Observable<Blob> {
    return this.http.get(`/api/Users/${userId}/Logo`, { responseType: 'blob' });
  }

  getUsersForDelegateUser(userId: string): Observable<UserDelegate[]> {
    return this.http.get<ListResponse<UserDelegate>>(`/api/Users/${userId}/DelegateOfUsers`)
      .pipe(map((x: ListResponse<UserDelegate>) => x.Entries));
  }

  getUserToolboxFavorites(userId: string): Observable<string[]> {
    return this.http.get<ListResponse<string>>(`/api/Users/${userId}/ToolboxFavorites`)
      .pipe(map((x: ListResponse<string>) => x.Entries));
  }

  postUserLogo(userId: string, formData: FormData): Observable<string> {
    return this.http.post(`/api/Users/${userId}/Logo`, formData, { responseType: 'text' });
  }

  updateUserToolboxFavorites(userId: string, favoriteIds: string[]): Observable<string[]> {
    return this.http.put<ListResponse<string>>(`/api/Users/${userId}/ToolboxFavorites`, favoriteIds)
      .pipe(map((x: ListResponse<string>) => x.Entries));
  }

  getAgentArcStatus(): Observable<AgentArcStatus> {
    return this.http.get<AgentArcStatus>('/api/Agents/AgentStatus');
  }
}
