<div class="flex flex-col w-full h-full mx-auto justify-center dialog-content">
  @if (isLoading){
    <div [@fadeIn] class="relative flex-grow p-20 loader-wrap">
      <div class="loader-sm is-blue"></div>
    </div>
  } @else {
    <section class="flex flex-col justify-center h-full gap-y-20" @fadeIn>
      @if (!isRedtailAuthenticated) {
        <div class="flex flex-col items-center m-auto gap-y-20 max-w-400">
          <div class="flex items-center justify-center mx-10">
            <img class="w-auto h-60" src="./assets/images/logos/redtail-logo.svg" alt="Redtail Logo">
          </div>
          <span class="m-10 text-lg">
            Welcome to Redtail. Enter your <strong>Username</strong> and <strong>Password</strong> below to continue.
          </span>
          <form [formGroup]="redtailAuthForm" class="flex-col w-full">
            <div class="flex flex-col flex-shrink-0 mx-10 mb-20 gap-y-5">
              <!-- <label for="username" class="text-sm label text-gray" for="username">Username</label> -->
              <input class="input" type="text" placeholder="Username" name="Username" formControlName="username" appCyData="redtailUsername" />
              @if (usernameCtrl.touched && usernameCtrl.errors) {
                <span class="w-full text-xs text-red">
                  @if (usernameCtrl.hasError('required')) {
                  <span>This field is required.</span>
                  }
                </span>
              }
            </div>
            <div class="flex flex-col flex-shrink-0 mx-10 gap-y-5">
              <!-- <label for="password" class="text-sm label text-gray" for="password">Password</label> -->
              <input class="input" type="password" placeholder="Password" formControlName="password" appCyData="redtailPassword" />
              @if (passwordCtrl.touched && passwordCtrl.errors) {
                <span class="w-full text-xs text-red">
                  @if (passwordCtrl.hasError('required')) {
                    <span>This field is required.</span>
                  }
                </span>
              }
            </div>
            <div class="flex items-center justify-center mx-10 mt-20 gap-y-10">
              <button mat-button class="ml-auto button is-primary" type="submit"
                      (click)="authenticateWithRedTail()"
                      [disabled]="isSubmitting"
                      appCyData="redtailConnectButton">
                Sign{{isSubmitting ? 'ing' : ''}} in
              </button>
            </div>
          </form>
        </div>
      } @else {
        <div class="flex flex-col gap-y-10 w-full h-full">
          <div class="bg-green-50 border border-green-200 rounded flex items-center p-10">
            <i class="material-icons text-green-500">
              check_circle
            </i>
            <span class="w-full text-base font-bold ml-5">Connected to Redtail CRM.</span>
            <button mat-button type="button" class="ml-auto button px-25 is-outlined is-warning"
                    (click)="openConfirmationModal()"
                    [disabled]="isSubmitting"
                    appCyData="redtailDisconnectButton">
              Disconnect{{isSubmitting ? 'ing' : ''}}
            </button>
          </div>
          <span class="my-10 divider col-span-full"></span>
            <app-start-crm-eapp class="flex" [crmName]="'Redtail'" @fadeIn></app-start-crm-eapp>
            @if (canEnroll){
              <div class="flex w-full justify-between items-start cursor-pointer p-10 hover:bg-blue-50 transition-colors duration-150"
                (click)="openLifecycleIntegrationModal()" @fadeIn>
                <div class="flex flex-col gap-y-5 max-w-280">
                  <span class="font-bold">Link Lifecycle Data to Redtail</span>
                  <span class="text-sm">Send your Lifecycle policy data to Redtail and connect to your Redtail contacts.</span>
                  <a class="text-sm text-blue-600 underline" target="_blank" href="https://support.redtailtechnology.com/s/article/Working-with-Unlinked-Accounts-in-Redtail" >
                    Linking accounts in Redtail CRM
                  </a>
                </div>
                @if (_isLcmConnected()){
                  <span class="bg-green-200 rounded-full px-20 py-5">Connected</span>
                }
                @else {
                  <span class="bg-gray-100 rounded-full px-20 py-5">Connect</span>
                }
              </div>
            }
          
        </div>
      }
    </section>
  }
</div>
