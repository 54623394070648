import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { FeatureFlagModel } from 'src/app/shared/models/feature-flag.model';
import { RiaAgent } from 'src/app/shared/models/ria.models';
import { EAppPreferenceEnum, UserSettingsModel } from 'src/app/shared/models/user-settings.model';
import { FigAuthUserId, User } from 'src/app/shared/models/user.models';
import { UserGroup } from 'src/app/shared/models/usergroups.models';
import { PermissionService } from '../auth/permission.service';
import { AgentLicenseApiService } from '../http/agent-license-api.service';
import { EnrollmentFormApiService } from '../http/enrollment-form-api.service';
import { FeatureFlagApiService } from '../http/feature-flag-api.service';
import { RedtailApiService } from '../http/redtail-api.service';
import { RiaApiService } from '../http/ria-api.service';
import { UserApiService } from '../http/user-api.service';
import { UserGroupApiService } from '../http/user-group-api.service';
import { UserSettingsApiService } from '../http/user-settings-api.service';
import { WealthboxApiService } from '../http/wealthbox-api.service';
import { UserVpApiService } from 'src/app/core/services/http/user-vp-api.service';
import { SalesforceSsoService } from '../http/salesforce-sso.service';
import { IcomplyApiService } from '../http/icomply-api.service';
import { IcomplyUserIsActive } from 'src/app/shared/models/icomply.models';

@Injectable({
  providedIn: 'root'
})
export class SessionStoreService {

  constructor(
    private agentLicenseApiService: AgentLicenseApiService,
    private enrollmentFormApiService: EnrollmentFormApiService,
    private featureFlagApiService: FeatureFlagApiService,
    private permissionService: PermissionService,
    private riaApiService: RiaApiService,
    private redtailApiService: RedtailApiService,
    private salesforceSsoService: SalesforceSsoService,
    private userApiService: UserApiService,
    private userGroupApiService: UserGroupApiService,
    private userSettingsApiService: UserSettingsApiService,
    private uservVpApiService: UserVpApiService,
    private wealthboxApiService: WealthboxApiService,
    private icomplyService: IcomplyApiService,
  ) { }

  private _user!: User;
  private _userGroup: UserGroup | null = null;
  private _onBehalfOfUser: User | null = null;
  private _onBehalfOfUserGroup: UserGroup | null = null;
  private _userLogo: string | null = null;
  private _userAcmAgent: RiaAgent | null = null;
  private _userSettings: UserSettingsModel | null = null;
  private _featureFlags: FeatureFlagModel[] | null = null;
  private _isWealthboxUser = false;
  private _isSalesforceUser = false;
  private _salesTeam: string | null = null;
  private _figAuthId: FigAuthUserId | null = null;
  private _isIcomplyUser: IcomplyUserIsActive | null;
  private _localStorageNames: SSLocalStorage = {
    icomplyUserIsActive: 'LS_iComplyUserIsActive',
    featureFlags: 'LS_featureFlags',
    userSettings: 'LS_userSettings',
    userArcAgentStatus: 'LS_userArcAgentStatus',
  };

  get IsIcomplyUser(): IcomplyUserIsActive {
    return this._isIcomplyUser;
  }

  get User(): User {
    return this._user;
  }

  get UserGroup(): UserGroup | null {
    return this._userGroup;
  }

  get OnBehalfOfUser(): User | null {
    return this._onBehalfOfUser;
  }

  get OnBehalfOfUserGroup(): UserGroup | null {
    return this._onBehalfOfUserGroup;
  }

  get UserLogo(): string | null {
    return this._userLogo;
  }

  get UserAcmAgent(): RiaAgent | null {
    return this._userAcmAgent;
  }

  get UserSettings(): UserSettingsModel | null {
    return this._userSettings;
  }

  get UserFeatureFlag(): FeatureFlagModel[] | null {
    return this._featureFlags;
  }

  get SalesTeam(): string | null {
    return this._salesTeam;
  }

  get IsSalesforceUser(): boolean {
    return this._isSalesforceUser;
  }

  get FigAuthId(): FigAuthUserId | null {
    return this._figAuthId;
  }

  get IsWealthboxUser(): boolean {
    return this._isWealthboxUser;
  }

  setAgentLicenses(Crd: string, Npn: string): Observable<null> {
    if (this._onBehalfOfUser) throw 'Unable to update NPN/CRD on behalf of someone';
    return this.agentLicenseApiService.updateUserAgentLicenses(Crd, Npn)
      .pipe(
        tap(() => {
          this._user.AgentLicenses.Npn = Npn;
          this._user.AgentLicenses.Crd = Crd;
        })
      );
  }

  updateEAppPreference(eAppPreference: EAppPreferenceEnum): Observable<UserSettingsModel> {
    if (!this._userSettings) throw 'User settings do not exist for this user';
    return this.userSettingsApiService.put({ ...this._userSettings, EAppPreference: eAppPreference })
      .pipe(
        tap((res: UserSettingsModel) => this._userSettings = res)
      );
  }

  setUser(): Promise<void | AgentLicenses | {
    Npn: null;
    Crd: null;
  }> {
    return this.userApiService.getUserInfo().toPromise()
      .then((user) => {
        this._user = user;
        return this.agentLicenseApiService.getLoggedInUserAgentLicenses().toPromise()
          .then(agentLicenses => this._user.AgentLicenses = agentLicenses)
          .catch(() => this._user.AgentLicenses = { Npn: null, Crd: null })
          .finally(() => {
            if (this._user.UserGroupId) {
              localStorage.setItem('UserGroupId', this._user.UserGroupId);
              return this.userGroupApiService.get(this._user.UserGroupId).toPromise().then(userGroup => this._userGroup = userGroup);
            } else {
              localStorage.removeItem('UserGroupId');
            }
            return null;
          });
      })
      .catch(() => {
        throw new Error('Unable to get user');
      });
  }

  async setUserV2(): Promise<User | null> {
    try {
      const user: User = await this.userApiService.getUserInfo().toPromise();
      const isArcUser: boolean = await this.userApiService.getIsArcUser().toPromise().catch(() => user.IsArcUser = false);
      const agentLicenses = await this.agentLicenseApiService.getLoggedInUserAgentLicenses().toPromise().catch(() => user.AgentLicenses = { Npn: null, Crd: null });
      const isRedtailUser = await this.redtailApiService.isUserAuthenticated().toPromise().catch(() => false);
      const isWealthboxUser = await this.wealthboxApiService.isConnected().toPromise().catch(() => false);
      const isSalesforceUser = await this.salesforceSsoService.isConnected().toPromise().catch(() => false);
      const isToTUser = await this.enrollmentFormApiService.getAgentEnrollment(21).toPromise().catch(() => false);
      const salesTeam = await this.uservVpApiService.getSalesTeam().toPromise().catch(() => '');
      const agentArcStatus = await this.userApiService.getAgentArcStatus().toPromise().catch<null>(() => null);
      this.icomplyService.postConfigureUser().subscribe({ error: error => console.log(error) });
      user.IsArcUser = isArcUser;
      user.AgentLicenses = agentLicenses;
      user.IsRedtailUser = isRedtailUser;
      user.IsToTUser = isToTUser;
      user.SalesTeam = salesTeam;
      user.AgentArcStatus = agentArcStatus;
      this._isSalesforceUser = isSalesforceUser;
      this._isWealthboxUser = isWealthboxUser;
      return this._user = user;
    } catch {
      return null;
    }
  }

  async setUserLocalStorageData(): Promise<boolean> {
    try {
      this._userSettings = await this.setUserPropsWithLocalStorageAsync<UserSettingsModel>(this._localStorageNames.userSettings, this.userSettingsApiService.get());
      this._isIcomplyUser = await this.setUserPropsWithLocalStorageAsync<IcomplyUserIsActive>(this._localStorageNames.icomplyUserIsActive, this.icomplyService.getIcomplyUserIsActive());
      this._featureFlags = await this.setUserPropsWithLocalStorageAsync<FeatureFlagModel[]>(this._localStorageNames.featureFlags, this.featureFlagApiService.getAllFlags());
      return true;
    } catch {
      return false;
    }
  }

  async setUserGroup(user: User | null): Promise<UserGroup | null> {
    if (!user?.UserGroupId) {
      localStorage.removeItem('UserGroupId');
      return null;
    } else {
      const userGroup = await this.userGroupApiService.get(user.UserGroupId).toPromise().catch(() => this._userGroup = null);
      localStorage.setItem('UserGroupId', user.UserGroupId);
      this._userGroup = userGroup;
      return userGroup;
    }
  }

  async setUserLogo(): Promise<string | null | undefined> {
    if (!this._user) return null;

    if (this._onBehalfOfUser?.UserGroupId) {
      try {
        await this.userGroupApiService.getLogo(this._onBehalfOfUser.UserGroupId).toPromise();
        return this._userLogo = `/api/UserGroups/${this._onBehalfOfUser.UserGroupId}/Logo`;
      } catch {
        return this._userLogo = null;
      }
    } else if (this._user.UserGroupId) {
      try {
        await this.userGroupApiService.getLogo(this._user.UserGroupId).toPromise();
        return this._userLogo = `/api/UserGroups/${this._user.UserGroupId}/Logo`;
      } catch {
        return this._userLogo = null;
      }
    } else if (this._onBehalfOfUser) {
      try {
        await this.userApiService.getUserLogo(this._onBehalfOfUser.Id).toPromise();
        return this._userLogo = `/api/Users/${this._onBehalfOfUser.Id}/Logo`;
      } catch {
        return this._userLogo = null;
      }
    } else {
      try {
        await this.userApiService.getUserLogo(this._user.Id).toPromise();
        return this._userLogo = `/api/Users/${this._user.Id}/Logo`;
      } catch {
        return this._userLogo = null;
      }
    }
  }

  /**
   * Clears a single/all Local Sorage values that are used by session store
   * @param localStorageName (Optional) If provided, only remove this specific LS value
   */
  clearSessionStoreLocalStorage(localStorageName?: SSLocalStorageNames): void {
    if (localStorageName) {
      localStorage.removeItem(localStorageName);
    } else {
      for (const [, value] of Object.entries(this._localStorageNames)) {
        localStorage.removeItem(value);
      }
    }
  }

  /**
   * TODO: Still need work to account for props that can change on the fly
   * Allows us to set any particular property that requires an API call out to retrieve data.
   * The data is saved to Local Storage and will be removed upon signing out.
   * If Local Storage data exists it will retrieve the data from there instead of making the same API calls again
   * @param localStorageName Name of the property as saved in Local Storage. Please use the `SSLocalStorageNames` interface to add new LS names to avoid spelling errors
   * @param observable The angular http request that returns an `Observable<T>`
   * @returns The promised value of type `T` from either LS or API call
   */
  private async setUserPropsWithLocalStorageAsync<T>(localStorageName: SSLocalStorageNames, observable: Observable<T | null>): Promise<T | null> {
    const ls = localStorage.getItem(localStorageName);
    let res: Promise<T>;
    // TODO: Not fully sure how many API calls will return a valid response that is either null || undefined, since those can be valid response
    // But to be safe, we're only storing the values if they are truthy
    if (!ls || JSON.parse(ls) === null || JSON.parse(ls) === undefined) {
      res = await observable.toPromise().catch(() => null) as Promise<T>;
      if (res != null) {
        localStorage.setItem(localStorageName, JSON.stringify(res));
      }
    } else {
      res = JSON.parse(ls) as Promise<T>;
    }
    return res;
  }

  async setUserFigAuthId(): Promise<FigAuthUserId | null | undefined> {
    if (!this._user?.Id) return undefined;
    const id = this._onBehalfOfUser ? this._onBehalfOfUser.Id : this._user.Id;
    this._figAuthId = await this.userApiService.getUserFigAuthId(id).toPromise();
    return this._figAuthId;
  }

  async setOnBehalfOfUserV2(): Promise<User | null> {
    const onBehalfOfId = this.permissionService.getOnBehalfOfUserId();
    if (onBehalfOfId) {
      const user = await this.userApiService.get(onBehalfOfId).toPromise();
      this._onBehalfOfUser = user;

      let agentLicenses = null;
      try {
        agentLicenses = await this.agentLicenseApiService.getUserAgentLicenses().toPromise();
        if (agentLicenses && agentLicenses.Crd && agentLicenses.Npn) this._onBehalfOfUser.AgentLicenses = agentLicenses;
      } catch (e) {
        console.error(e);
        this._onBehalfOfUser.AgentLicenses = { Npn: null, Crd: null };
      }

      if (this._onBehalfOfUser && this._onBehalfOfUser.UserGroupId) {
        const onBehalfOfUserGroup = await this.userGroupApiService.get(this._onBehalfOfUser.UserGroupId).toPromise();
        this._onBehalfOfUserGroup = onBehalfOfUserGroup;
      }
    }

    return this._onBehalfOfUser;
  }

  setOnBehalfOfUser(): Promise<void | AgentLicenses | {
    Npn: null;
    Crd: null;
  }> | undefined {
    const onBehalfOfId = this.permissionService.getOnBehalfOfUserId();
    if (onBehalfOfId) {
      return this.userApiService.get(onBehalfOfId).toPromise().then(user => {
        this._onBehalfOfUser = user;
        return this.agentLicenseApiService.getUserAgentLicenses().toPromise()
          .then(agentLicenses => this.OnBehalfOfUser.AgentLicenses = agentLicenses)
          .catch(() => this._user.AgentLicenses = { Npn: null, Crd: null })
          .finally(() => {
            if (this._onBehalfOfUser && this._onBehalfOfUser.UserGroupId) {
              return this.userGroupApiService.get(this._onBehalfOfUser.UserGroupId).toPromise().then(userGroup => this._onBehalfOfUserGroup = userGroup);
            }
            return null;
          });
      })
        .catch(() => console.log('error getting on behalf of user'));
    }
    return null;
  }

  async setUserAcmAgentV2(): Promise<RiaAgent | undefined> {
    let acmAgent;
    try {
      acmAgent = await this.riaApiService.getAcmAgent().toPromise();
      this._userAcmAgent = acmAgent;
    } catch (e) {
      console.log(e);
    }
    return acmAgent;
  }

  setUserAcmAgent(): Promise<void> {
    return this.riaApiService.getAcmAgent().toPromise()
      .then(riaAgent => {
        this._userAcmAgent = riaAgent;
        return;
      })
      .catch(error => {
        console.log(error);
      });
  }

  /** Used when user authenticate into redtail */
  setUserRedtailStatus(isActive: boolean): void {
    this._user.IsRedtailUser = isActive;
  }

  /** Used when user authenticate into Salesforce */
  setUserSalesforceStatus(isActive: boolean): void {
    this._isSalesforceUser = isActive;
  }

  /** Used when user authenticate into wealthbox */
  setUserWealthboxStatus(isActive: boolean): void {
    this._isWealthboxUser = isActive;
  }
}

type SSLocalStorage = {
  icomplyUserIsActive: SSLocalStorageNames,
  featureFlags: SSLocalStorageNames,
  userSettings: SSLocalStorageNames,
  userArcAgentStatus: SSLocalStorageNames,
}

type SSLocalStorageNames = 'LS_iComplyUserIsActive' | 'LS_featureFlags' | 'LS_userSettings' | 'LS_userArcAgentStatus';