import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { AgentLicenseApiService } from 'src/app/core/services/http/agent-license-api.service';
import { RedtailEnrolleeApiService } from 'src/app/core/services/http/redtail-enrollee-api.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ConfirmationComponent } from 'src/app/portal/modals/confirmation/confirmation.component';
import { SupportComponent } from 'src/app/portal/modals/support/support.component';
import { AgentLicenses } from 'src/app/shared/models/agent.models';
import { ConfirmationData } from 'src/app/shared/models/modal-data.models';

@Component({
  animations: [fadeIn],
  selector: 'app-redtail-crm-integration',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './redtail-crm-integration.component.html',
})
export class RedtailCrmIntegrationComponent implements OnInit {
  licenses: AgentLicenses = {Npn: null, Crd: null};
  isEnrolled = false;
  isLoading = false;
  isSubmitting = false;

  constructor(
    private agentLicenseService: AgentLicenseApiService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RedtailCrmIntegrationComponent>,
    private redtailEnrolleeService: RedtailEnrolleeApiService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.getCrmIds();
  }

  closeModal(): void {
    this.dialogRef.close({ refresh: true });
  }

  getCrmIds(): void {
    this.agentLicenseService.getUserAgentLicenses()
      .subscribe({
        next: response => {
          this.licenses = response;
        },
        error: () => {
          console.log('There was an error retrieving user licenses.');
        }
      })
      .add(() => this.getIsEnrolled());
  }

  getIsEnrolled(): void {
    this.isLoading = true;
    this.redtailEnrolleeService.isEnrolled()
      .subscribe({
        next: response =>{
          this.isEnrolled = response;
        }
      }).add(() => this.isLoading = false);
  }

  openConfirmationModal(): void {
    this.dialog.open<ConfirmationComponent, ConfirmationData>(ConfirmationComponent, {
      data: {
        message: 'Your connection request has been submitted successfully. Your accounts will appear in Redtail within 72 hours. If you have any further questions, please contact our support team.',
        isWarning: false,
        confirmationButtonText: 'Done',
        showSuccessIcon: true,
        hideCancelButton:true,
      }
    }).afterClosed().subscribe(() => {
      this.closeModal();
    });
  }

  openSupportModal(): void {
    this.dialog.open<SupportComponent>(SupportComponent, {});
  }

  submitRequest(): void {
    let message = '';
    let toastClass = ToastClassEnum.default;
    this.isSubmitting = true;
    this.redtailEnrolleeService.registerUser()
      .subscribe({
        next: () => {
          this.openConfirmationModal();
          message = 'You have successfully submitted your request.';
          toastClass = ToastClassEnum.success;
        }, error: () => {
          message = 'There was an error registering your request. Please try again later.';
          toastClass = ToastClassEnum.warning;
        }
      }).add(() => {
        this.isSubmitting = false;
        this.isEnrolled = true;
        this.snackbarService.openSnackbar(message, toastClass);
      });
  }
}
